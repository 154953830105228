import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_merchant_card = _resolveComponent("merchant-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        parentPath: "/profile",
        title: $setup.t('back')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment, {
                    color: "danger",
                    modelValue: $setup.typeFilter,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.typeFilter) = $event)),
                    mode: "ios"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_segment_button, { value: "likedProducts" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.t('UserProfilePage.likedProducts')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_segment_button, { value: "likedMerchants" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.t('UserProfilePage.likedMerchants')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              ($setup.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ion_spinner)
                  ]))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.likedProducts, (product) => {
                    return (_openBlock(), _createBlock(_component_ion_col, {
                      "size-xs": "6",
                      "size-md": "4",
                      "size-xl": "3",
                      key: product.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_product_card, {
                          product: product,
                          extraRouterParams: { parentPath: '/liked' }
                        }, null, 8, ["product"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 512), [
                [_vShow, $setup.typeFilter == 'likedProducts']
              ]),
              _withDirectives(_createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.likedMerchants, (merchant) => {
                    return (_openBlock(), _createBlock(_component_ion_col, {
                      "size-xs": "12",
                      "size-md": "6",
                      "size-lg": "4",
                      key: merchant.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_merchant_card, {
                          merchant: merchant,
                          extraRouterParams: { parentPath: '/liked' }
                        }, null, 8, ["merchant"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 512), [
                [_vShow, $setup.typeFilter == 'likedMerchants']
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}