
// Vue reactivity
import { computed, ref } from "vue";

// components
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonSpinner, 
        IonToolbar, IonSegment, IonSegmentButton, IonLabel, } from "@ionic/vue";
import ProductCard from "@/components/product/ProductCard.vue";
import MerchantCard from '@/components/MerchantCard.vue';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: "UserLikedItemListPage",
  components: {
    IonContent, IonPage, IonGrid, IonRow, IonCol, IonSpinner,
    IonToolbar, IonSegment, IonSegmentButton, IonLabel,
    ProductCard, MerchantCard,
  },
  setup() {
    const store = useStore();
    
    // 1. declare state variables (ref to make them reactive)
    const loading = ref(false);
    const typeFilter = ref('likedProducts');
    const likedProducts = computed(() => store.getters.userLikedItems);
    const likedMerchants = computed(() => store.getters.userLikedMerchants);

    // methods or filters
    const { t } = useI18n();

    // 3. return variables & methods to be used in template HTML
    return {
      // state variables
      loading, typeFilter,
      likedProducts, likedMerchants,
      
      // methods
      t,
    };
  },
};
